body {
  overflow-x: hidden;
  width: 100vw;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("../fonts/Graphik-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../fonts/Graphik-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../fonts/Graphik-Semibold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

button.btn {
  font-size: 12.5px !important;
}

#root {
  width: 100%;
}

.p-datatable-emptymessage td {
  width: 100%;
  border: 0 !important;
  font-family: "Graphik-Medium" !important;
}

.full-width-100 {
  width: 100%;
}

.ml-2 {
  margin-left: 2em !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.page-title {
  margin: 0;
  font-size: 18px;
  line-height: 1.5555556;
}

.content {
  padding-left: 25px;
  padding-right: 25px;
}

.content > .row > .add-button {
  padding: 15px 0 15px 15px;
}

.common-box {
  margin: 10px 10px 15px 10px;
}

.p-column-title,
.p-column-title > div {
  font-size: 12px;
  color: black;
}

* {
  font-family: "Graphik-Medium";
  letter-spacing: -0.29px;
}

.login-image {
  width: 125px;
  margin-left: -10px;
}

.not-active {
  opacity: 0.5;
  pointer-events: none;
}

.outline-cancel:disabled {
  opacity: 0.5;
}

.p-inputtext {
  height: 34px !important;
  font-size: 12px !important;
  border-radius: 3px;
  padding: 2px;
  border: 1px solid #ced4da;
  padding-left: 8px !important;
}

.p-calendar {
  width: 100%;
}

.modal .btn-outline-info,
.modal .btn-primary,
.modal .btn-secondary {
  border-radius: 20px !important;
  min-width: 80px !important;
  padding: 9px !important;
}

.p-button {
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}

.w-2px {
  width: 2px !important;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 17px;
}

.modal-footer {
  padding: 5px;
}

.form-control {
  color: #696e72;
  padding: 8px 8px 6px;
  height: 34px !important;
  font-size: 12.5px !important;
  border-radius: 3px;
  border: 1px solid #ced4da;
  padding-left: 8px !important;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-button-label {
  transition: all 0.2s;
  font-size: 12px !important;
}

.display-block {
  display: block !important;
}

.p-selectbutton .p-button.p-highlight {
  background: black !important;
  border-color: black;
}

.pointer-menu {
  cursor: pointer !important;
}

.dropdown-item,
.pi-user-edit {
  cursor: pointer;
}

.p-paginator .p-paginator-page.p-highlight {
  background: black !important;
  border-color: black !important;
  color: white !important;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  font-size: 12px;
  color: black !important;
  height: 25px !important;
  width: 25px !important;
  min-width: 25px !important;
  border: 1px solid #ccc !important;
  border-radius: 2px !important;
  background: white;
}

.p-paginator .p-dropdown {
  width: 50px !important;
  height: 25px !important;
  padding: 0px !important;
  padding-right: 6px !important;
  border-radius: 4px !important;
}

.p-paginator {
  border: 0;
}

.p-datepicker {
  z-index: 9999 !important;
}

.black-btn {
  background: black !important;
  color: white;
  font-size: 12px !important;
  border: 1px solid black;
}

.black-btn:hover {
  background: white !important;
  color: black;
  border: 1px solid black;
}

.p-datepicker table th,
.p-datepicker table td {
  padding: 2px;
  font-size: 11px;
  text-align: center;
}

.p-datepicker .p-disabled {
  background: #ccc;
}

.p-datepicker .p-datepicker-header {
  font-size: 14px;
  background: #e0e0e0 !important;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  padding: 3px !important;
}

.p-datepicker table td > span {
  width: 25px;
  height: 25px;
  color: black;
  font-family: "Graphik-Medium";
  font-size: 12px !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
  height: 22px;
  font-size: 12px;
  padding-bottom: 1rem;
  line-height: normal;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  margin-right: 0.5rem;
  height: 22px;
  font-size: 12px;
  padding-bottom: 1rem;
  line-height: normal;
}

.p-selectbutton .p-button.p-highlight {
  background: #514182;
}

.p-selectbutton .p-button.p-highlight:hover {
  border-color: rgb(81 65 130 / 79%) !important;
  background-color: rgb(81 65 130 / 79%) !important;
}

.shadow-blue {
  background: rgb(192, 220, 235);
}

.list-star li :hover {
  background: #6651a81a !important;
}

.option-dropdown .p-dropdown-item {
  white-space: pre-wrap;
}

.option-dropdown .p-dropdown-items-wrapper {
  max-width: 265px;
}

.p-dropdown-items-wrapper {
  border: 1px solid #cccccc8a;
  border-top: 0;
}

.mar-t-1 {
  margin-top: 1px !important;
}

.overlay-items:hover {
  color: rgb(117, 0, 192) !important;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 5px;
  font-size: 12.5px;
}

.p-dropdown-panel {
  z-index: 9999 !important;
}

.p-dropdown-item {
  font-size: 12px !important;
  padding: 5px 5px 5px !important;
}

.p-multiselect .p-multiselect-label {
  padding: 5px 8px !important;
  font-size: 12.5px;
  color: #495057;
}

.p-checkbox {
  width: 17px !important;
  height: 19px !important;
}

.p-checkbox .p-checkbox-box {
  width: 17px !important;
  height: 17px !important;
  color: #495057;
  border-radius: 3px;
  border: 1.5px solid #ced4da;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: black;
  background: #e5e7eb;
}

.pi-check:before {
  content: "✔";
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: rgb(55, 65, 81);
  background: rgb(55, 65, 81);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  height: 100%;
  width: 100%;
  text-align: center;
}

.p-multiselect-items-wrapper {
  margin-bottom: 5px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 14px;
  margin-right: 5px;
  margin-left: 25px;
  height: 14px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
  padding: 10px;
  font-size: 12.5px;
  text-align: left;
}

.p-multiselect .p-multiselect-trigger {
  margin-right: 5px;
  margin-top: -4px;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 8px 0px 5px 10px !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background: #e5e7eb;
  border-bottom: 1px solid #f8f9fa;
  height: 35px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 6px;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 5px;
}

.p-multiselect-panel {
  z-index: 9999 !important;
  color: #495057;
  background: rgb(229, 231, 235);
  border-radius: 3px;
  border: 1.3px solid rgb(156, 163, 175);
}

.p-multiselect-item {
  font-size: 12px;
  padding: 5px 10px 5px 5px !important;
}

span.p-dropdown-trigger-icon {
  font-size: 12.5px;
}

span.p-multiselect-trigger-icon {
  font-size: 12.5px;
  margin-top: 3px;
}

.pad-10 {
  padding: 10px !important;
}

.p-dropdown,
.p-multiselect {
  width: 100%;
  height: 34px;
}

.p-dropdown-trigger,
.p-multiselect-trigger {
  justify-content: end !important;
  width: fit-content !important;
}

.p-breadcrumb,
.p-breadcrumb ul {
  padding: 0;
  border: none;
  background-color: transparent;
  margin: 0;
  font-size: 15px !important;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a {
  text-decoration: none !important;
}

.action-filter .p-column-header-content {
  width: 80%;
}

.action-filter .common-filter {
  position: fixed;
  right: 10px;
}

.p-toast-message {
  z-index: 9999999 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
  font-size: 12px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0.5rem;
}

.p-toast {
  opacity: 1;
  z-index: 999999 !important;
  position: fixed;
  width: 20rem !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: rgb(243, 253, 231);
  border: 2px solid rgb(95, 165, 13);
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #ffe7e6;
  border: 2px solid #ea6560;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #bbd0f2;
  border: 2px solid #3b82f6;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fbbf24;
  border: 2px solid #cc8925;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
  color: black;
  margin-left: 35px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 15px;
  height: 26px;
  width: 26px;
  position: absolute;
  border-radius: 100%;
  color: white !important;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
  color: black !important;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fbbf24 !important;
}

.p-toast-message-success .p-toast-message-icon {
  background: rgb(95, 165, 13);
}

.p-toast-message-error .p-toast-message-icon {
  background: #ea6560;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 25px !important;
  height: 25px !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
  font-size: 14px;
}

.main-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;
  opacity: 1;
  background: white;
  overflow: hidden;
}

.main-loader .p-progress-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 45%;
  top: 45%;
}

.absolute-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.position-absolute {
  position: absolute;
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: rgb(161, 0, 255);
  }

  40% {
    stroke: rgb(70, 17, 101);
  }

  66% {
    stroke: rgb(148, 183, 236);
  }

  80%,
  90% {
    stroke: rgb(81 65 130 / 79%);
  }
}

.page-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.page-loader .p-progressbar {
  margin-top: 80px !important;
}

.p-progressbar .p-progressbar-value {
  background: rgb(161, 0, 255) !important;
  opacity: 0.5;
}

.p-autocomplete-panel.p-component.p-connected-overlay-enter-done {
  z-index: 100111 !important;
}

.overlay-list {
  list-style-type: none;
  min-height: 20px;
  font-size: 12px;
}

.p-listbox .p-listbox-header {
  padding: 5px 10px;
  background: white;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  left: 0.75rem;
  width: 0.75rem;
  color: #6c757d;
}

.p-listbox .p-listbox-list .p-listbox-item {
  border-bottom: 1px solid #ccc !important;
  padding: 0px;
}

.p-listbox .p-listbox-list .col-12 {
  padding: 5px;
}

.p-listbox .form-check .form-check-label {
  color: black !important;
  line-height: 1.5 !important;
  margin-left: 25px !important;
  font-size: 12px !important;
  margin-bottom: 0;
}

.p-listbox .form-check-input:checked {
  background-color: black;
  border-color: black;
}

.p-listbox .form-check-input[type="checkbox"] {
  height: 17px;
  width: 17px;
}

.custom-radio {
  height: 20px;
  width: 20px;
}

.checkbox-control {
  height: 15px;
  width: 15px;
}

.p-listbox .form-check {
  min-height: 0px !important;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
  height: 30px !important;
  padding-left: 2rem !important;
  border-radius: 20px;
}

.p-listbox .p-listbox-list {
  padding-top: 0 !important;
  padding-left: 0;
}

.field-required {
  color: red;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #4338ca;
  background: white;
}

textarea {
  width: 100% !important;
  overflow: auto !important;
  height: 34px !important;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.mar-top-4 {
  margin-top: 4px !important;
}

.disabled-row {
  opacity: 0.2;
  pointer-events: none;
}

.disabled-section {
  opacity: 0.7;
  pointer-events: none;
}

.margin-t-3 {
  margin-top: 3px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 26px;
  font-size: 11px;
  margin-top: -5px;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
  margin-top: 40%;
}

.p-dropdown.p-disabled,
.p-calendar-disabled input {
  background: #f5f4f4;
  color: black !important;
}

.dots-txt {
  display: inline-block;
  width: 98px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 1.5rem;
  height: 1.5rem;
}

.p-multiselect-header .p-checkbox-box::after {
  content: "All";
  font-size: 12.5px;
  position: absolute;
  left: 22px;
  top: 0.5px;
}

.user-dropdown {
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.form-check-input:checked {
  background-color: #4e565a;
  border-color: #4e565a;
}

.custom-checkbox {
  height: 15px;
  width: 15px;
}

.table-striped td {
  padding: 10px !important;
  border: 0 !important;
}

.table-striped tr {
  border: 0.5px solid rgb(220, 221, 223);
}

.table-striped {
  border: 1px solid rgb(220, 221, 223);
  background: #f9f9f9;
}

.table-striped > :not(:first-child) {
  border: 0.5px solid rgb(220, 221, 223);
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding: 0;
}

.font-30 {
  font-size: 30px !important;
}

.p-calendar .p-datepicker-touch-ui {
  width: 32%;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 32%;
  transform: translate(-50%, -50%);
  border: 1.5px solid #ccc;
  background: #fff;
}

@media only screen and (max-width: 768px) {
  .mob-center {
    text-align: center !important;
  }

  .mob-btm-padding {
    margin-bottom: 10px;
  }

  .landing-links-1 {
    margin-top: -230px !important;
    padding-left: 20px;
  }

  .landing-links-2 {
    margin-top: -155px !important;
    padding-left: 20px;
  }

  .landing-links {
    padding-left: 30px !important;
  }

  .landing-banner-img {
    height: 500px !important;
  }

  .p-datatable .p-datatable-thead > tr > th {
    width: 150px;
    min-width: 150px;
  }

  .p-calendar .p-datepicker-touch-ui {
    width: 70%;
  }
}

.p-column-filter-clear-button {
  display: none;
}

.red-color {
  color: red;
}

.overflow-x-row {
  overflow-x: auto;
  overflow-y: hidden;
}

.progress-width {
  width: "60px";
  height: "60px";
}

span.pi.pi-calendar {
  background: url("../images/calendar-alt.svg");
  background-repeat: no-repeat;
}

.pi-calendar:before {
  visibility: hidden;
}

.show-scroll {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.row-filter {
  height: 25px;
  width: 25px;
}

.p-tooltip-bottom .p-tooltip-arrow {
  top: -16px;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 10px 7px;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 10px 5px;
  font-size: 12.5px;
  background: white;
  border: 1.5px solid #9ea5b1;
  margin-right: -10px;
  margin-left: -50px;
  min-width: 40px;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #e5e7eb !important;
}

.p-overlaypanel:before {
  border-bottom-color: #9ea5b1;
}

.p-overlaypanel:after {
  border-bottom-color: #e5e7eb;
}

.p-overlaypanel-enter-done {
  z-index: 9999 !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  bottom: 100%;
  left: calc(var(--overlayArrowLeft, 0) + 10px);
  margin-bottom: -1.5px;
}

.section-overlay {
  background: rgb(229, 231, 235) !important;
  border-radius: 5px !important;
  border: 0px solid rgb(219 222 227) !important;
}

.section-overlay > div {
  background: rgb(229, 231, 235) !important;
  border-radius: 5px;
}

.error-overlay > div {
  padding: 20px 15px 15px 0px !important;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #9ea5b1;
  margin-top: -2px;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  margin-top: -2px;
}

.p-datatable-loading-overlay {
  background: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin: 2px 2px -2px 2px;
}

*:focus {
  box-shadow: none !important;
}

.last-element {
  height: 20px;
}

.margin-8 {
  margin-top: -8px !important;
}

.user-is-tabbing .focus-me:focus {
  outline: dotted black !important;
  outline-offset: 1px;
  outline-style: auto;
  border-radius: 2px;
}

h5.card-title {
  min-height: 15px;
}

.p-inputwrapper.round-bar .p-inputtext {
  height: 34px !important;
}

.p-dropdown span.p-inputtext {
  height: 20px !important;
}

input[type="checkbox"] {
  transform: scale(1) !important;
}

table td span {
  font-family: "Graphik-Regular";
}
table td .p-multiselect-items span {
  font-family: "Graphik-Medium";
}

table td .p-multiselect-items span {
  font-family: "Graphik-Medium";
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding: 5px 10px;
  font-size: 12px;
}

.p-autocomplete-panel .p-autocomplete-items-wrapper .p-autocomplete-items {
  padding-left: 0;
}

nav .header-dropdown .p-dropdown-label {
  margin-top: -2px !important;
}

.row-action-btn {
  background-color: transparent !important;
  border: 0;
}

.edit-tool,
.edit-blue-tool,
.eye-tool,
.add-tool,
.filter-tool,
.dot-tool,
.bell-tool,
.qstn-tool,
.plus-no-tool,
.minus-tool,
.plain-minus-tool,
.delete-tool,
.excla-icon,
.newlyadded-icon,
.draftinprogress-icon,
.success-icon,
.readyforinterview-icon,
.inprogress-icon,
.delete-blue-tool,
.next-week-icon,
.previous-week-icon,
.next-time-icon,
.previous-time-icon,
.expiring-input-icon {
  background-repeat: no-repeat;
  position: unset;
  cursor: pointer;
  width: 19px;
  height: 19px;
  display: inline-flex;
  background-color: transparent !important;
  border: 0;
}

.next-week-icon {
  background: url("../images/panel-right-week.png");
  background-size: 100%;
}
.expiring-input-icon {
  background-image: url("../images/expiring-input-icon.png");
  background-size: 100%;
}

.expiring-input-icon {
  background-image: url("../images/expiring-input-icon.png");
  background-size: 100%;
}

.previous-week-icon {
  background: url("../images/panel-left-week.png");
  background-size: 100%;
}

.next-time-icon {
  background: url("../images/panel-right-time.png");
  background-size: 100%;
}

.previous-time-icon {
  background: url("../images/panel-left-time.png");
  background-size: 100%;
}

.excla-icon {
  background-image: url("../images/excla.png");
  background-size: 100%;
}

.newlyadded-icon {
  background-image: url("../images/newlyadded.png");
  background-size: 100%;
}

.draftinprogress-icon {
  background-image: url("../images/draftinprogress.png");
  background-size: 100%;
}

.inprogress-icon {
  background-image: url("../images/current-interview.png");
  background-size: 100%;
}

.success-icon {
  background-image: url("../images/success.png");
  background-size: 100%;
}

.readyforinterview-icon {
  background-image: url("../images/readyforinterview.png");
  background-size: 100%;
}

.edit-tool {
  background-image: url("../images/edit.svg");
  background-size: 100%;
}

.edit-blue-tool {
  background-image: url("../images/interviewer-edit.svg");
  background-size: 100%;
}

.eye-tool {
  background-image: url("../images/eye.svg");
  background-size: 100%;
  margin-top: 0px !important;
}

.delete-tool {
  background-image: url("../images/delete.svg");
  background-size: 85%;
}

.delete-blue-tool {
  background-image: url("../images/interviewer-delete.svg");
  background-size: 85%;
}

.filter-tool {
  background-image: url("../images/filter@2x.png");
  background-size: 85%;
}

.add-tool {
  background-image: url("../images/plus.svg");
  background-size: 100%;
  width: 21px;
  height: 21px;
}

.dot-tool {
  background-image: url("../images/3dots.svg");
  background-size: 100%;
}

.bell-tool {
  background-image: url("../images/bell.svg");
  background-size: 100%;
}

.qstn-tool {
  background-image: url("../images/qstn.svg");
  background-size: 100%;
}

.plus-no-tool {
  background-image: url("../images/plus-no-border.svg");
  background-size: 100%;
}

.minus-tool {
  background-image: url("../images/minus.svg");
  background-size: 100%;
}

.plain-minus-tool {
  background-image: url("../images/plain-minus-tool.svg");
  background-size: 100%;
}

.btn-img-text {
  width: auto !important;
  background-size: auto;
  padding-left: 23px !important;
  background-color: transparent !important;
  border: 0;
  display: block;
}

.landing-cards {
  margin-top: -60px;
}

.landing-banner {
  margin-top: -8px !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.landing-boxes {
  border-radius: 7px;
}

.landing-links {
  position: absolute;
  width: 100%;
  color: white;
  font-size: 13px;
  padding-left: 100px;
  padding-right: 100px;
}

.landing-logout {
  float: right;
  position: absolute;
  right: 15px;
}

.landing-links-1 {
  margin-top: -125px;
}

.landing-links-2 {
  margin-top: -100px;
}

.landing-links-head {
  margin-top: 130px;
}

.landing-icons {
  background: white;
  padding: 3px;
  border-radius: 100%;
}

.landing-banner-img {
  height: 400px;
}

.landing-links .row-action-btn {
  color: white !important;
}

.terms-of-use label {
  line-height: 15px !important;
  margin-bottom: 15px !important;
}

.terms-of-use {
  line-height: 15px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-error-head {
  font-family: "Graphik-Semibold" !important;
  font-size: 40px;
}

.app-error-btn {
  border-radius: 20px !important;
  background-color: rgba(255, 255, 255, 1) !important;
  color: rgb(161, 0, 255) !important;
  border: 0 !important;
  padding: 10px 20px !important;
  font-size: 12.5px !important;
}

.app-error-sub-head {
  font-family: "Graphik-Semibold" !important;
  font-size: 40px;
  display: block;
}

.app-error-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  background-image: url("../images/access-denied-bg.png");
  background-repeat: no-repeat;
  justify-content: center;
}

.app-error-wrapper-404 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: 100% 100%;
  background-image: url("../images/error-404-bg.png");
  background-repeat: no-repeat;
  justify-content: center;
}

.app-error-wrapper-404 .black-text {
  color: rgb(21, 24, 27) !important;
}

.app-head-section {
  margin-top: 135px;
  width: 70%;
  color: white;
}

.app-head-section i {
  color: white;
  font-size: 13px;
}

.font-15 {
  font-size: 15px !important;
}

.hide-tab {
  display: none !important;
}

.hide-tab-header .p-tabview-nav-container {
  display: none !important;
}

.p-datepicker-mask-scrollblocker {
  display: none;
}

button.p-button {
  background: #fff;
  color: #495057;
  border-color: #ced4da;
  height: 34px !important;
  padding: 2px;
  width: 30px !important;
  border-left-color: #ced4da;
}

button.p-button:hover {
  background-color: #fff !important;
  color: rgb(118, 118, 118) !important;
}

.p-calendar .p-inputtext {
  height: 34px !important;
  font-size: 12px !important;
  border-radius: 5px 0px 0px 5px;
  border-right: 0;
  font-family: "Graphik-Medium";
}

.p-calendar .p-button {
  height: 34px !important;
  width: 34px !important;
  border-radius: 0px 5px 5px 0px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 2px 0px;
}

.p-multiselect-close-icon {
  display: none;
}

.p-datatable-wrapper.horizondal-scroller-active {
  cursor: pointer;
}

.multiselect-panel-header {
  padding: 5px 0px 5px 10px !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background: #e5e7eb;
  border-bottom: 1px solid #f8f9fa;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.multiselect-panel-header-checkbox {
  margin-right: 6px !important;
}

.p-multiselect-header .p-checkbox-box::after {
  content: attr(data-value) !important;
}

.red-color {
  color: #a30000;
}

.blue-color {
  color: rgb(0, 65, 240) !important;
}

.green-color {
  color: green;
}

.orange-color {
  color: orange;
}

.disabled-sub-row {
  pointer-events: none;
}

.disabled-sub-row .p-dropdown,
.disabled-sub-row .p-calendar input,
.disabled-sub-row textarea,
.disabled-sub-row input:not(input[type="checkbox"]):not(input[type="radio"]),
.disabled-sub-row .p-autocomplete .p-autocomplete-multiple-container,
.disabled-sub-row .p-autocomplete input {
  background: #e9ecef !important;
}

.box-border {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.outline-btn-h-34 {
  height: 34px !important;
}
.round-icon,
.expiring-input-icon {
  width: 16px !important;
  height: 16px !important;
}

.p-dialog .p-dialog-header {
  padding: 10px 15px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dialog .p-dialog-footer {
  padding: 10px 15px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 16px;
}

.p-confirm-dialog .p-dialog-content {
  font-size: 12px;
  padding: 10px 15px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 15px;
}

.p-dialog-footer button {
  padding: 10px 15px !important;
  border: 1px solid #ccc !important;
  margin: 0;
  margin-left: 5px;
  border-radius: 4rem !important;
  min-width: 90px;
}

.p-confirm-dialog-accept {
  border: 1px solid rgb(0, 65, 240) !important;
  color: white !important;
  background: rgb(0, 65, 240) !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0 !important;
}

.border-p-table .p-datatable {
  border: 1px solid #ccc;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.p-tabview-nav-next,
.p-tabview-nav-prev {
  width: 2rem !important;
  box-shadow: none !important;
  border-radius: 0;
}

.error-li {
  font-size: 12px;
  line-height: 20px;
}

button.p-toast-icon-close {
  width: 40px !important;
  height: 20px !important;
  padding: 5px;
}

.height-25 {
  height: 25px !important;
}

.disabled-sub-row .p-highlight {
  background: white !important;
}

.p-dialog-visible {
  z-index: 99999 !important;
}

.w-95 {
  width: 95% !important;
}

.width-150 {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}

.width-70 {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
}

@media (min-width: 1025px) {
  .width-120 {
    width: 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
  }

  .width-450 {
    width: 450px !important;
    max-width: 450px !important;
    min-width: 450px !important;
  }

  .width-140 {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }

  .width-80 {
    width: 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
  }

  .width-95 {
    width: 95px !important;
    max-width: 95px !important;
    min-width: 95px !important;
  }

  .width-100 {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
  }

  .width-110 {
    width: 110px !important;
    max-width: 110px !important;
    min-width: 110px !important;
  }

  .width-175 {
    width: 175px !important;
    max-width: 175px !important;
    min-width: 175px !important;
  }

  .width-185 {
    width: 185px !important;
    max-width: 185px !important;
    min-width: 185px !important;
  }

  .width-50 {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .width-40 {
    width: 40px !important;
    min-width: 40px !important;
    max-width: 40px !important;
  }

  .width-25 {
    width: 25px !important;
    min-width: 25px !important;
    max-width: 25px !important;
  }

  .width-20 {
    width: 20px !important;
    min-width: 20px !important;
    max-width: 20px !important;
  }

  .width-70 {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
  }

  .width-200 {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
}

.self-dropdowns ul {
  margin-bottom: 0px !important;
}

.p-datepicker-touch-ui .p-datepicker-title {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.p-datepicker-touch-ui .p-datepicker-title .p-dropdown span.p-inputtext {
  height: 34px !important;
  margin-top: -9px !important;
}

.p-datepicker-touch-ui .p-datepicker-title .p-dropdown {
  padding: 5px !important;
  height: 28px !important;
  margin-right: 3px !important;
  margin-top: 0px;
}

.p-datepicker-touch-ui .p-datepicker-header {
  padding: 5px !important;
}

.p-paginator button i {
  padding-top: 5px;
  font-size: 13px;
}

.error-li-wcag {
  display: none;
}

.user-is-tabbing .red-color .error-li-wcag {
  display: flex;
}

.enable-view-mode *:not(div) {
  pointer-events: none !important;
}

.enable-view-mode .enable-pointer,
.enable-view-mode .p-row-toggler,
.enable-view-mode .p-paginator *,
.enable-view-mode .p-tabview-nav-content * {
  pointer-events: all !important;
}

.india-disable-controls button,
.india-disable-controls .custom-checkbox {
  opacity: 0.4;
  pointer-events: none;
}

.india-disable-controls .general-controls,
.india-disable-controls .filter-tool,
.india-disable-controls .custom-disable,
.india-disable-controls .p-row-toggler {
  opacity: 1 !important;
  pointer-events: all !important;
}

.add-edit-disabled .custom-disable {
  opacity: 0.4 !important;
  pointer-events: none !important;
}

.box-border-round {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.card-border-1 {
  border: 1px solid #ccc !important;
}

.w-90per {
  width: 90%;
}

.max-content-width {
  width: max-content !important;
}

.p-message {
  margin: 0;
  z-index: 99999;
  position: fixed;
  top: 80px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0px;
  padding: 5px 15px;
  box-shadow: 4px 20px 30px 10px rgb(0 0 0 / 6%);
}

.p-message.p-message-warn {
  background: #f6e2ae;
  border: #f6e2ae;
  border-width: 0px;
  color: white;
  font-size: 12px !important;
  border: 1px solid #cc8925 !important;
  border-width: 1px 0px 1px 0 !important;
}

.p-message-icon,
.p-message-summary {
  display: none;
}

.p-message-detail {
  font-size: 12.5px !important;
  color: black;
}

.p-message .p-message-wrapper {
  padding: 0;
}

.header-alert-update-bg {
  position: fixed;
  background-color: #b1d2eb;
  padding: 20px;
  z-index: 1031;
  font-size: 13px;
  width: 100%;
  right: 0;
  left: 0px;
  margin: 0;
}

.header-title-font {
  font-size: 17px !important;
}

@media (max-width: 992px) {
  .header-title-font {
    font-size: 14px !important;
  }
}
@media (min-width: 993px) {
  .modal-desktop-hide {
    display: none !important;
  }
}

.no-pointer-events {
  pointer-events: none !important;
}

.language-option-dropdown {
  width: 150px;
}
